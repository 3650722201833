import fetchAxios from '../../middlewares/axios';
import {
  LIST_CAMPS,
  LIST_CAMPSV2,
  LOAD_CAMP,
  SET_CAMPS,
  SET_CAMPSV2,
  SET_CAMP,
  UPDATE_CAMP,
  SAVE_CAMP,
  REMOVE_CAMP,
  SETACTIVE_CAMP,
  MAIL_WAITINGLIST,
  LOAD_CAMPV2,
  SET_CAMPV2,
  SAVE_CAMPV2,
  UPDATE_CAMPV2,
  LIST_CAMPSDATA,
  SET_CAMPDATA,
  LOAD_CAMPDATA,
  SAVE_CAMPDATA,
  REMOVE_CAMPDATA,
  UPDATE_CAMPDATA,
  SET_CAMPSDATA,
} from './types';
import { ROOT_URL_PROTECTED } from '../../../config/axios';

export const listCamps = () => {
  return (dispatch) => {
    dispatch({ type: LIST_CAMPS, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.camps
          ) {
            dispatch({
              type: SET_CAMPS,
              camps: response.data.camps,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_CAMPS });
          reject({ error: e });
        });
    });
  };
};

export const listCampsData = () => {
  return (dispatch) => {
    dispatch({ type: LIST_CAMPSDATA, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/data`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.campsdata
          ) {
            dispatch({
              type: SET_CAMPSDATA,
              campsdata: response.data.campsdata,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_CAMPSDATA });
          reject({ error: e });
        });
    });
  };
};

export const listCampsV2 = () => {
  return (dispatch) => {
    dispatch({ type: LIST_CAMPSV2, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/new`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.camps
          ) {
            dispatch({
              type: SET_CAMPSV2,
              campsv2: response.data.camps,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LIST_CAMPSV2 });
          reject({ error: e });
        });
    });
  };
};

export const showCamp = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_CAMP, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.camp
          ) {
            dispatch({
              type: SET_CAMP,
              campDetail: response.data.camp,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_CAMP });
          reject({ error: e });
        });
    });
  };
};

export const listRegistrations = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_CAMPDATA, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/data/registrations/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.registrations
          ) {
            resolve(response.data);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_CAMPDATA });
          reject({ error: e });
        });
    });
  };
};

export const showCampData = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_CAMPDATA, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/data/detail/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.campdataDetail
          ) {
            dispatch({
              type: SET_CAMPDATA,
              campdataDetail: response.data.campdataDetail,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_CAMPDATA });
          reject({ error: e });
        });
    });
  };
};

export const showCampV2 = (id) => {
  return (dispatch) => {
    dispatch({ type: LOAD_CAMPV2, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'get',
        `${ROOT_URL_PROTECTED}/camps/detailnew/${id}`,
        null,
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.camp
          ) {
            dispatch({
              type: SET_CAMPV2,
              campDetailv2: response.data.camp,
            });
            resolve(response);
          } else {
            reject('LOADERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: LOAD_CAMPV2 });
          reject({ error: e });
        });
    });
  };
};

export const mailWaitingList = (id) => {
  return (dispatch) => {
    dispatch({ type: MAIL_WAITINGLIST, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/mailwaitinglist`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: MAIL_WAITINGLIST,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((e) => {
          dispatch({ type: MAIL_WAITINGLIST });
          reject({ error: e });
        });
    });
  };
};

export const saveCamp = () => {
  return (dispatch, getState) => {
    const { camps } = getState();
    const { longDescriptionState, ...rest } = camps.campDetail;
    dispatch({ type: SAVE_CAMP, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/update`,
        {
          camp: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.camp
          ) {
            dispatch({
              type: SET_CAMP,
              campDetailv2: response.data.camp,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_CAMP });
          reject({ error: e });
        });
    });
  };
};

export const saveCampData = (theCamp) => {
  return (dispatch, getState) => {
    const { camps } = getState();
    const { longDescriptionState, ...rest } = camps.campdataDetail;
    dispatch({ type: SAVE_CAMPDATA, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/data/update`,
        {
          campdata: {
            ...rest,
            camp: theCamp,
          },
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.campdataDetail
          ) {
            dispatch({
              type: SET_CAMPDATA,
              campdataDetail: response.data.campdataDetail,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_CAMPDATA });
          reject({ error: e });
        });
    });
  };
};

export const removeRegistrations = (campurl) => {
  return (dispatch) => {
    dispatch({ type: SAVE_CAMPV2, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/removeregistrations`,
        {
          campurl,
        },
        null,
        true
      )
        .then((response) => {
          if (response && response.data && response.data.success) {
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_CAMPV2 });
          reject({ error: e });
        });
    });
  };
};

export const saveCampV2 = () => {
  return (dispatch, getState) => {
    const { camps } = getState();
    const { longDescriptionState, ...rest } = camps.campDetailv2;
    dispatch({ type: SAVE_CAMPV2, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/updatenew`,
        {
          camp: rest,
        },
        null,
        true
      )
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.success &&
            response.data.camp
          ) {
            dispatch({
              type: SET_CAMPV2,
              campDetailv2: response.data.camp,
            });
            resolve(response.data);
          } else {
            reject('SAVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SAVE_CAMPV2 });
          reject({ error: e });
        });
    });
  };
};

export const setCampsActiveById = (campids) => {
  return (dispatch) => {
    dispatch({ type: SETACTIVE_CAMP, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/setactivebyids`,
        {
          campids,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: SETACTIVE_CAMP,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: SETACTIVE_CAMP });
          reject({ error: e });
        });
    });
  };
};

export const removeCamp = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_CAMP, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_CAMP,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_CAMP });
          reject({ error: e });
        });
    });
  };
};

export const removeCampData = (id) => {
  return (dispatch) => {
    dispatch({ type: REMOVE_CAMPDATA, isFetching: true });
    return new Promise(function (resolve, reject) {
      fetchAxios(
        dispatch,
        'post',
        `${ROOT_URL_PROTECTED}/camps/data/remove`,
        {
          id,
        },
        null,
        true
      )
        .then((response) => {
          dispatch({
            type: REMOVE_CAMPDATA,
          });
          if (response && response.data && response.data.success) {
            resolve();
          } else {
            reject('REMOVEERROR');
          }
        })
        .catch((e) => {
          dispatch({ type: REMOVE_CAMPDATA });
          reject({ error: e });
        });
    });
  };
};

export const updateCamp = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CAMP,
      field,
      value,
    });
  };
};

export const updateCampData = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CAMPDATA,
      field,
      value,
    });
  };
};

export const updateCampV2 = (field, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CAMPV2,
      field,
      value,
    });
  };
};

export const resetCamp = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAMP,
      campDetail: copy || {},
    });
  };
};

export const resetCampData = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAMPDATA,
      campdataDetail: copy || {},
    });
  };
};

export const resetCampV2 = (copy) => {
  return (dispatch) => {
    dispatch({
      type: SET_CAMPV2,
      campDetailv2: copy || {},
    });
  };
};
