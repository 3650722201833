import { Component } from 'react';
import { connect } from 'react-redux';
import StyledTable from '../../../components/StyledTable';
import { showSnack } from '../../../redux/actions/app';
import swal from 'sweetalert';
import './style.scss';
import { listCampsV2, removeCamp } from '../../../redux/actions/camps';

const fields = [
  {
    title: 'Kamp',
    prop: 'title',
  },
  {
    title: 'URL',
    prop: 'fullURL',
  },
  {
    title: 'Verborgen',
    prop: 'unavailable',
    boolAsCheck: true,
  },
];

const tableActions = ['edit', 'delete'];

class CampsV2 extends Component {
  state = {
    itemsSorted: undefined,
    activeSort: 'title',
    selectedItems: [],
  };

  componentDidMount = () => {
    this.props.doListCampsV2();
  };

  editCamp = (camp) => {
    return this.props.history.push(`/campsv2/detail/${camp._id}`);
  };

  deleteCamp = (camp) => {
    swal({
      title: 'Item verwijderen',
      text: 'Bent u zeker dat u het item wil verwijderen?',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((result) => {
      if (result) {
        this.props
          .doRemoveCampV2(camp._id)
          .then(() => {
            this.props.doSnack('Item verwijderd', 'success');
            this.props.doListCampsV2();
          })
          .catch(() => {
            this.props.doSnack('Probleem bij verwijderen');
          });
      }
    });
  };

  setSelectedItem = (itemID, selected) => {
    const { selectedItems } = this.state;
    let newSelectedItems = [...selectedItems];
    if (selected) {
      newSelectedItems.push(itemID);
    } else {
      const ind = selectedItems.indexOf(itemID);
      if (ind > -1) {
        newSelectedItems.splice(ind, 1);
      }
    }
    this.setState({
      selectedItems: newSelectedItems,
    });
  };

  setSelectedCampsActive = () => {
    this.props
      .doSetCampsActiveById(this.state.selectedItems)
      .then(() => {
        swal('Gelukt', 'Kampen actief gezet', 'success');
        this.props.doListCamps();
      })
      .catch((e) => {
        console.log(e);
        swal('Probleem', 'Probleem bij actief zetten van kampen', 'error');
      });
  };

  render() {
    const { campsv2 } = this.props;
    return (
      <div>
        <StyledTable
          title="Kampen"
          fields={fields}
          bodyItems={campsv2 || []}
          actions={tableActions}
          addNew="Nieuw kamp"
          addNewLink="/campsv2/new"
          onEdit={this.editCamp}
          onDelete={this.deleteCamp}
          selectable={false}
          changeSelectedItems={this.setSelectedItem}
          tableAction={this.onTableAction}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    campsv2: state.camps.campsv2,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    doListCampsV2: () => {
      dispatch(listCampsV2());
    },
    doRemoveCampV2: (id) => {
      return dispatch(removeCamp(id));
    },
    doSnack: (message, messageType, delay, i18n) => {
      dispatch(showSnack(message, messageType, delay, i18n));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CampsV2);
