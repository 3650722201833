import Logo from './assets/images/jeppasport_zwart.svg';
export const appVersion = '2.5';
export const AppName = 'Jeppa CMS';
export const mainColor = '#d2db2f';
export const awsAccessKey = 'AKIAWSERQOV2P4DJ6ROE'; //'AKIAWSERQOV2FGNSVCFV';
export const awsSecretKey = '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'; //'IF8y7mFDT1E1X6XQCnqYOVE8umTKhlXn0MsgQL//';
export const bucketName = 'jeppauploads';
export const frontEndLanguages = [
  {
    iso: 'nl',
    language: 'Nederlands',
  },
];
export const logo = Logo;
